import React, { useEffect, useRef } from "react";
import { useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import './InfoBar.css';
import { openModal } from '../../reducers/modalSlice';

const InfoBar = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const currentCourse = searchParams.get('courseName');
  const razorpayButtonRef = useRef();
  const scriptAdded = useRef(false); // Flag to track if the script is already added
  
    useEffect(() => {
      if (!scriptAdded.current) {
        // Ensure the script is appended only once
        if (razorpayButtonRef.current) {
          const script = document.createElement("script");
          script.src = "https://checkout.razorpay.com/v1/payment-button.js";
          script.setAttribute("data-payment_button_id", "pl_PXoxjOA9dZHK7r");
          script.async = true;
  
          script.onerror = () => {
            console.error("Failed to load Razorpay script");
          };
  
          razorpayButtonRef.current.appendChild(script);
          scriptAdded.current = true; // Mark as added
        }
      }
    }, []);

  return (
    <div className="info-bar">
      {currentCourse === 'react' ? (
        <p className="announcement">
          <span className="highlight">Learn React JS with GENAI!</span>
          <button
            className="enroll-button"
            // onClick={() => dispatch(openModal('React Campaign Enroll'))}
          >
             <form ref={razorpayButtonRef}></form>
          </button>
        </p>
      ) : (
        <p className="announcement">
          <span onClick={() => dispatch(openModal('Frontend Master'))}>
            Click here to join our Training program!
          </span>
        </p>
      )}
    </div>
  );
};

export default InfoBar;
