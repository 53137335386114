import React, { useEffect, useRef } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Box,
  Chip,
  Divider,
  List,
  ListItem,
  ListItemText,
  Button,
} from "@mui/material";
import EventIcon from "@mui/icons-material/Event";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import DateRangeIcon from "@mui/icons-material/DateRange";
import SchoolIcon from '@mui/icons-material/School';
import WorkIcon from '@mui/icons-material/Work';

const CourseInfo = () => {
  const razorpayButtonRef = useRef();
  const scriptAdded = useRef(false); // Flag to track if the script is already added

  useEffect(() => {
    if (!scriptAdded.current) {
      // Ensure the script is appended only once
      if (razorpayButtonRef.current) {
        const script = document.createElement("script");
        script.src = "https://checkout.razorpay.com/v1/payment-button.js";
        script.setAttribute("data-payment_button_id", "pl_PXoxjOA9dZHK7r");
        script.async = true;

        script.onerror = () => {
          console.error("Failed to load Razorpay script");
        };

        razorpayButtonRef.current.appendChild(script);
        scriptAdded.current = true; // Mark as added
      }
    }
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        background: "linear-gradient(135deg, #f3e7e9, #e3eef6)",
        padding: 2,
        margin: '10px 0'
        
      }}
    >
      <Card
        sx={{
          maxWidth: 700,
          width: "100%",
          borderRadius: "20px",
          boxShadow: "0 10px 20px rgba(0, 0, 0, 0.1)",
          overflow: "hidden",
          backgroundColor: "#fff",
        }}
      >
        <Box
          sx={{
            background: "#594444",
            padding: "20px 10px",
            textAlign: "center",
          }}
        >
          <Typography variant="h4" sx={{ color: "#fff", fontWeight: "bold" }}>
            React.js with GenAI
          </Typography>
          <Typography variant="subtitle1" sx={{ color: "#e0e0e0" }}>
            A Modern Approach to Learning
          </Typography>
        </Box>

        <CardContent sx={{ background: "#efefef", padding: "30px" }}>
          <Typography variant="h5" gutterBottom sx={{ textAlign: "center", fontWeight: "bold" }}>
            Course Details
          </Typography>
          <Divider sx={{ marginY: 2, backgroundColor: "#e0e0e0" }} />

          <Grid container spacing={3} justifyContent="center">
            <Grid item xs={12} sm={6}>
              <Box textAlign="center">
                <EventIcon sx={{ fontSize: "2rem", color: "#4caf50" }} />
                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                  Start Date
                </Typography>
                <div>Weekend: 4th Jan 2025</div>
                <div>Weekday: 6th Jan 2025</div>
              </Box>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Box textAlign="center">
                <DateRangeIcon sx={{ fontSize: "2rem", color: "#2196f3" }} />
                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                  Mode
                </Typography>
                <Chip label="Online" color="primary" size="small" />
              </Box>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Box textAlign="center">
                <AccessTimeIcon sx={{ fontSize: "2rem", color: "#ff9800" }} />
                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                  Daily Hours
                </Typography>
                <Typography variant="body2">1 Hour</Typography>
              </Box>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Box textAlign="center">
                <AccessTimeIcon sx={{ fontSize: "2rem", color: "#ff5722" }} />
                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                  Completion Time
                </Typography>
                <Typography variant="body2">15-18 Days (Approx)</Typography>
              </Box>
            </Grid>

            <Grid item xs={12} sm={6}>
                <Box textAlign="center">
                <SchoolIcon sx={{ fontSize: "2rem", color: "#8e24aa" }} />
                <Typography variant="body1" sx={{ fontWeight: "bold", fontSize: "1rem" }}>
                    Certification
                </Typography>
                <Typography variant="body2" sx={{ fontSize: "0.875rem" }}>
                    Get a recognized certificate upon successful completion.
                </Typography>
                </Box> 
            </Grid>

            {/* New Section for Placement Assistance */}
            <Grid item xs={12} sm={6}>
                <Box textAlign="center">
                <WorkIcon sx={{ fontSize: "2rem", color: "#3f51b5" }} />
                <Typography variant="body1" sx={{ fontWeight: "bold", fontSize: "1rem" }}>
                    Placement Assistance
                </Typography>
                <Typography variant="body2" sx={{ fontSize: "0.875rem" }}>
                    We provide placement assistance to help you kick-start your career.
                </Typography>
                </Box>
            </Grid>
          </Grid>

          <Divider sx={{ marginY: 4, backgroundColor: "#e0e0e0" }} />

          {/* Course Highlights Section */}
          <Typography variant="h5" gutterBottom sx={{ textAlign: "center", fontWeight: "bold" }}>
            Course Highlights
          </Typography>
          <List sx={{ paddingX: 2 }}>
            <ListItem>
              <ListItemText
                primary="Learn React.js fundamentals and advanced concepts."
                primaryTypographyProps={{
                  fontSize: "1rem",
                  color: "#555",
                  textAlign: "center",
                }}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Explore Generative AI tools for modern app development."
                primaryTypographyProps={{
                  fontSize: "1rem",
                  color: "#555",
                  textAlign: "center",
                }}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Build AI-powered React.js applications with hands-on projects."
                primaryTypographyProps={{
                  fontSize: "1rem",
                  color: "#555",
                  textAlign: "center",
                }}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Get mentorship from industry experts."
                primaryTypographyProps={{
                  fontSize: "1rem",
                  color: "#555",
                  textAlign: "center",
                }}
              />
            </ListItem>
          </List>
          <Divider sx={{ marginY: 4, backgroundColor: "#e0e0e0" }} />
          <Box textAlign="center">
            <Typography variant="h6" sx={{ fontWeight: "bold", marginBottom: 2 }}>
            Join the Future of Web Development: Learn React and GenAI Today!
            </Typography>
            <form ref={razorpayButtonRef}></form>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default CourseInfo;
