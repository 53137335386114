import React from 'react';
import './CourseAdvantages.css';
import { FaProjectDiagram, FaChalkboardTeacher, FaCertificate, FaBriefcase, FaLaptopCode, FaUserGraduate, FaRegClock, FaTools, FaHandsHelping } from 'react-icons/fa';

const CourseAdvantages = () => {
    const advantages = [
        { text: "Complete this course in a short time frame.", icon: FaRegClock, color: "#FF5722" }, // Orange
        { text: "Gain hands-on experience with real-time projects.", icon: FaLaptopCode, color: "#3F51B5" }, // Indigo
        { text: "Get your doubts clarified by industry experts.", icon: FaChalkboardTeacher, color: "#4CAF50" }, // Green
        { text: "Learn from instructors working at leading multinational companies.", icon: FaBriefcase, color: "#FFC107" }, // Amber
        { text: "Receive a certificate to boost your resume and career opportunities.", icon: FaCertificate, color: "#9C27B0" }, // Purple
        { text: "Gain the skills to take on freelance projects after course completion.", icon: FaTools, color: "#009688" }, // Teal
        { text: "Benefit from 100% job assistance to help you land your desired role.", icon: FaHandsHelping, color: "#795548" }, // Brown
        // { text: "Attend courses in a smart classroom environment for an immersive learning experience, with both online and offline options available.", icon: FaProjectDiagram, color: "#FF9800" }, // Orange
        // { text: "Enjoy a flexible learning schedule with both online and offline options available.", icon: FaRegClock, color: "#00BCD4" }, // Cyan
        { text: "Receive comprehensive support and guidance even after completing the course.", icon: FaUserGraduate, color: "#607D8B" } // Blue Grey
    ];

  return (
    <div className="course-advantages-section">
      <h2 className="page-title">Course Advantages</h2>
      <div className="course-advantages-list">
        {advantages.map((advantage, index) => {
          const Icon = advantage.icon;
          return (
            <div key={index} className="course-advantage-item">
              <Icon className="course-icon" style={{ color: advantage.color, width: '24px', height: '24px' }} /> 
              <span>{advantage.text}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CourseAdvantages;
