import React from 'react';
import { useDispatch } from 'react-redux';
import { openModal } from '../../../reducers/modalSlice';
import './CourseList.css';

import { FaClock, FaCertificate } from 'react-icons/fa';
import CourseBanner from '../../CoursePage/CourseBanner/CourseBanner';
import { syllabusTopics } from '../../CoursePage/courseModel'

const courses = Object.values(syllabusTopics).filter(item => item.showCourse)

const CourseList = ({ showAllCourses }) => {
  const displayedCourses = showAllCourses ? courses : courses.slice(0, 3);
  const dispatch = useDispatch();

  return (
    <>
      <CourseBanner showAllCourses={showAllCourses} />
      <div className="small-banner"></div>
      <div className="course-list">
        {displayedCourses.map((course, index) => (
          <div className="free-course-card" key={index}>
            <img
              src={course.image}
              alt={course.title}
              className="course-image"
            />
            <div className="card-content">
              <div className="title-wrapper">
                <h2 className="course-title">{course.title}</h2>
                {course.isFree && <div className="free-alert">Free</div>}
              </div>
              <p className="course-description">{course.description}</p>
              <ul className="course-details">
                <li>
                  <FaClock className="icon" color="#594444" /> <strong>Hours:</strong>{' '}
                  <span>{course.hours} hours</span>
                </li>
                <li>
                  <FaCertificate className="icon" color="green"/>{' '}
                  <strong>Certification:</strong>{' '}
                  <span>Yes, upon successful completion</span>
                </li>
              </ul>
              <div className="card-buttons">
                <div className="card-button-btn">
                  <a className="btn btn-enroll" href={course.link}>
                    Read More
                  </a>
                </div>
                {/* <div className="card-button-btn">
                  <span
                    className="btn btn-readmore"
                    onClick={() => dispatch(openModal(course.title))}
                  >
                    Enroll Now
                  </span>
                </div> */}
              </div>
            </div>
          </div>
        ))}
      </div>
      {!showAllCourses && (
        <div className="explore-button-container">
          <a href="/courses">
            <button className="btn btn-explore-more">
              Explore More Courses
            </button>
          </a>
        </div>
      )}
    </>
  );
};

export default CourseList;
